import { AuthRedirectService, WindowRef } from '@spartacus/core';
import { KurzCountrySelectorModalData } from '../kurz-components/shared/kurz-country-selector/kurz-country-selector-modal/kurz-country-selector-modal.component';


export function kurzMachineRedirect(opt: {
  windowRef?: WindowRef;
  /**
   * target redirect
   * consists in this project of "/{baseSite}/{language}/{currency}/"
   */
  siteContext?: string;
  /**
   * can be used to (roughly) determin 'siteContext'
   */
  countryCode?: KurzCountrySelectorModalData['selected'];
  authRedirectService?: AuthRedirectService;
}) {

  const _window = opt.windowRef?.nativeWindow || window;
  const location = _window.location;

  const urlHost = location.host;
  const isLocalhost = urlHost.includes('localhost');

  let machineSuffix: '' | 'dev' | 'qa' = '';

  if (!isLocalhost) {
    if (urlHost.includes('dev.kurz')) {
      machineSuffix = 'dev';
    }

    if (urlHost.includes('qa.kurz')) {
      machineSuffix = 'qa';
    }
  }

  if (!opt.siteContext && opt.countryCode) {

    switch(opt.countryCode) {
      case 'de':
        opt.siteContext = '/kurz-de/de/EUR/';
        break;
      case 'fr':
        opt.siteContext = '/kurz-fr/fr/EUR/';
        break;
      case 'uk':
        opt.siteContext = '/kurz-uk/en/GBP/';
        break;
      case 'ch':
        opt.siteContext = '/kurz-ch/de/CHF/';
        break;
      case 'nz':
        opt.siteContext = '/kurz-nz/en/NZD/';
        break;
      case 'au':
        opt.siteContext = '/kurz-au/en/AUD/';
        break;
      case 'us':
        opt.siteContext = '/kurz-us/en_US/USD/';
        break;
      case 'benelux':
        opt.siteContext = '/kurz-nl/nl/EUR/';
        break;
    }

  }

  let redirectUrl = opt.siteContext;

  if (!isLocalhost) {
    redirectUrl = `https://${machineSuffix ? (machineSuffix + '.') : ''}kurz.shop${opt.siteContext}`;
  }

  if (opt.authRedirectService) {
    opt.authRedirectService.setRedirectUrl(opt.siteContext);
  }

  location.href = redirectUrl;

}
